import { Link } from 'components';
import { useGetToken } from 'packages/tokens';
import { useTranslation } from 'packages/translations';
import { useChainId } from 'packages/wallet';

import supraLogo from 'assets/img/logo/supra.svg';
import { useGetBlockNumber } from 'clients/api';
import { useGetChainMetadata } from 'hooks/useGetChainMetadata';

import { IconLink } from './IconLink';
import {
  // SATURN_MEDIUM_URL,
  SATURN_FB_URL, // SATURN_DISCORD_URL,
  SATURN_GITHUB_URL,
  SATURN_TELEGRAM_URL,
  SATURN_TWITTER_URL,
} from './constants';

export const Footer: React.FC = () => {
  const { chainId } = useChainId();
  const { data: getBlockNumberData } = useGetBlockNumber();

  const { t } = useTranslation();
  const currentBlockNumber = getBlockNumberData?.blockNumber;
  const xvs = useGetToken({
    symbol: 'JLEND',
  });

  const { explorerUrl } = useGetChainMetadata();

  return (
    <footer className="flex h-14 flex-none items-center justify-between bg-background px-4 sm:justify-end md:px-6 xl:px-10">
      {!!currentBlockNumber && (
        <Link
          className="text-sm text-grey hover:no-underline sm:mr-6"
          href={explorerUrl}
          target="_blank"
          rel="noreferrer"
        >
          {t('footer.latestNumber')}
          <br className="sm:hidden" /> <span className="text-offWhite">{currentBlockNumber}</span>
        </Link>
      )}

      {/* <div className="flex">
        <IconLink
          href={
            xvs &&
            generateChainExplorerUrl({
              hash: xvs.address,
              urlType: 'token',
              chainId,
            })
          }
          iconName="venus"
        /> */}
      {/* <IconLink iconName="discord" href={SATURN_DISCORD_URL} /> */}
      <IconLink iconName="telegram" href={SATURN_TELEGRAM_URL} />
      <IconLink iconName="twitter" href={SATURN_TWITTER_URL} />
      {/* <IconLink iconName="medium" href={SATURN_MEDIUM_URL} /> */}
      <IconLink iconName="github" href={SATURN_GITHUB_URL} />
      <IconLink iconName="facebook" href={SATURN_FB_URL} />
      {/* </div>  */}
      <a href="https://supraoracles.com" target="_blank" rel="noreferrer">
        <img src={supraLogo} alt="Supra" className="ml-4 h-8" />
      </a>
    </footer>
  );
};
