import { SVGProps } from 'react';

const SvgDashboard = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    {...props}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.9423 11.7388C5.53186 12.6312 5.53186 13.6464 5.53186 15.6769V22.0182C5.53186 24.838 5.53186 26.248 6.40787 27.124C7.25736 27.9735 8.60892 27.9992 11.2614 28V20.5228C11.2614 19.1446 12.3786 18.0274 13.7568 18.0274H18.2432C19.6214 18.0274 20.7386 19.1446 20.7386 20.5228V28C23.3911 27.9992 24.7426 27.9735 25.5921 27.124C26.4681 26.248 26.4681 24.838 26.4681 22.0182V15.6769C26.4681 13.6464 26.4681 12.6312 26.0577 11.7388C25.6473 10.8464 24.8764 10.1857 23.3348 8.86433L21.8394 7.58252C19.0529 5.19409 17.6596 3.99988 16 3.99988C14.3404 3.99988 12.9471 5.19409 10.1607 7.58252L8.6652 8.86433C7.12356 10.1857 6.35275 10.8464 5.9423 11.7388ZM18.7386 28V20.5228C18.7386 20.2492 18.5168 20.0274 18.2432 20.0274H13.7568C13.4832 20.0274 13.2614 20.2492 13.2614 20.5228V28H18.7386Z"
      fill="currentColor"
      className="fill-current"
    />
  </svg>
);

export default SvgDashboard;
