/* Automatically generated file, do not update manually */
export default {
  VenusLens: {
    8899: '0x9a36Cbf929F5D3d75969d0c9F699B4244a2a4721',
  },
  PoolLens: {},
  PoolRegistry: {},
  LegacyPoolComptroller: {
    8899: '0x603122Cdd36abCD164e448e1f3fbd33730edf35a',
  },
  VaiController: {},
  VaiVault: {},
  XvsVault: {},
  XvsStore: {},
  GovernorBravoDelegate: {},
  XvsVesting: {},
  VrtConverter: {},
  Maximillion: {
    8899: '0xd9fFB90472a8B3700843bE75afec101dB00f0579',
  },
  XsequenceMulticall: {
    8899: '',
  },
  Multicall3: {
    8899: '0xc0C8C486D1466C57Efe13C2bf000d4c56F47CBdC',
  },
  ResilientOracle: {
    8899: '0x89661591D76D93a86B6Fe6DC464275581a0FD005',
  },
  Prime: {},
  SwapRouter: {},
  Faucet: {},
};
