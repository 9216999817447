export const theme = {
  fontFamily: {
    // sans: ['ProximaNova', 'Arial', 'sans-serif'],
    sans: ['Inter', 'Arial', 'sans-serif'],
    heading: ['Montserrat', 'system-ui', 'sans-serif'],
    karla: ['Karla', 'system-ui', 'sans-serif'],
  },
  container: {
    center: true,
    padding: '1rem',
  },
  screens: {
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1280px',
    xxl: '1440px',
  },
  colors: {
    inherit: 'inherit',
    transparent: 'transparent',
    // The next colors are taken from the UI kit in Figma
    // background: '#181D27',
    background: '#000000',
    // cards: '#1E2431',
    cards: '#171E28',
    grey: '#AAB3CA',
    lightGrey: '#35455C',
    lighterGrey: '#99A0B8',
    green: '#FF5F09',
    red: '#E93D66',
    offWhite: '#FFFFFF',
    orange: '#F57842',
    darkOrange: '#F36A2F',
    yellow: '#F5B242',
    blue: '#FF5F09',
    // The next colors are not in the UI kit, but are used throughout the designs
    mediumBlue: '#FF5F09',
    darkBlue: '#C14300',

    danger: '#f32f2f',
    warning: '#ff762d',
    success: '#FF5F09',
    greyScale: {
      light: '#ffffff',
      300: '#979797',
      400: '#3d3c3c',
      500: '#292929',
      600: '#141414',
      dark: '#000000',
    },
    primary: {
      100: '#ffb48c',
      200: '#ff945b',
      300: '#ff762d',
      DEFAULT: '#ff5f09',
      400: '#e55000',
      500: '#c14300',
      600: '#852e00',
    },
  },
  // fontSize: {
  //   xs: ['0.75rem', '1.5'],
  //   sm: ['0.875rem', '1.5'],
  //   base: ['1rem', '1.5'],
  //   lg: [
  //     '1.25rem',
  //     {
  //       lineHeight: '1.5',
  //       fontWeight: '600',
  //     },
  //   ],
  //   xl: [
  //     '1.5rem',
  //     {
  //       lineHeight: '1.5',
  //       fontWeight: '700',
  //     },
  //   ],
  //   '2xl': [
  //     '2rem',
  //     {
  //       lineHeight: '1.5',
  //       fontWeight: '600',
  //     },
  //   ],
  //   '3xl': [
  //     '2.5rem',
  //     {
  //       lineHeight: '1.2',
  //       fontWeight: '600',
  //     },
  //   ],
  // },
  boxShadow: {
    DEFAULT: '0px 4px 15px 0px #0D1017',
  },
  extend: {
    backgroundImage: {
      'my-gradient': 'linear-gradient(180deg, #1F2836 0%, #171E28 100%)',
    },
    content: {
      link: 'url(/public/icons/external-link.svg)',
    },
  },
  // We keep Tailwind's original sizing scale but make it more granular (with 0.25rem steps) and
  // extend it to bigger values
  spacing: new Array(200).fill(undefined).map((_, index) => `${index * 0.25}rem`),
};
