import { SVGProps } from 'react';

const SvgPool = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 32 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x="5.33331"
      y="5.33331"
      width="8"
      height="8"
      rx="4"
      fill="#979797"
      stroke="#979797"
      stroke-width="2"
      stroke-linejoin="round"
    />
    <rect
      x="5.33331"
      y="18.6667"
      width="8"
      height="8"
      rx="4"
      fill="#979797"
      stroke="#979797"
      stroke-width="2"
      stroke-linejoin="round"
    />
    <rect
      x="18.6667"
      y="18.6667"
      width="8"
      height="8"
      rx="4"
      fill="#979797"
      stroke="#979797"
      stroke-width="2"
      stroke-linejoin="round"
    />
    <rect
      x="18.6667"
      y="5.33331"
      width="8"
      height="8"
      rx="4"
      fill="#979797"
      stroke="#979797"
      stroke-width="2"
      stroke-linejoin="round"
    />
  </svg>
);

export default SvgPool;
