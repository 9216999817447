import * as React from 'react';
import { SVGProps } from 'react';

const SvgHistory = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    {...props}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16 29C23.1797 29 29 23.1797 29 16C29 8.8203 23.1797 3 16 3C8.8203 3 3 8.8203 3 16C3 23.1797 8.8203 29 16 29ZM17 8.05558C17 7.50329 16.5523 7.05558 16 7.05558C15.4477 7.05558 15 7.50329 15 8.05558V15.75C15 16.4404 15.5596 17 16.25 17H21.0556C21.6078 17 22.0556 16.5523 22.0556 16C22.0556 15.4477 21.6078 15 21.0556 15H17V8.05558Z"
      fill="currentColor"
      className="fill-current"
    />
  </svg>
);

export default SvgHistory;
