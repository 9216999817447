import { ErrorBoundary } from "packages/errors";
import { Web3Wrapper } from "packages/wallet";
import { Suspense, lazy } from "react";
import { QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";

import { queryClient } from "clients/api";
import { Layout } from "containers/Layout";
import { SentryErrorInfo } from "packages/errors/SentryErrorInfo";
import { MuiThemeProvider } from "theme/MuiThemeProvider";

import Router from "./Router";

const NotificationCenter = lazy(
  () => import("packages/notifications/NotificationCenter")
);

const App = () => (
  <ErrorBoundary>
    <MuiThemeProvider>
      <QueryClientProvider client={queryClient}>
        <Web3Wrapper>
          <SentryErrorInfo />

          {/* <AnalyticProvider> */}
          <BrowserRouter>
            <Layout>
              <Router />
            </Layout>

            {/* <AppVersionChecker /> */}

            {/* <LunaUstWarningModal /> */}

            <Suspense>
              <NotificationCenter />
            </Suspense>
          </BrowserRouter>
          {/* </AnalyticProvider> */}
        </Web3Wrapper>
      </QueryClientProvider>
    </MuiThemeProvider>
  </ErrorBoundary>
);

export default App;
