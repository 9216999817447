import localConfig from 'config';
import { ChainId } from 'types';
import { defineChain } from 'viem';
import { type Chain, bsc, bscTestnet, mainnet, modeTestnet, sepolia } from 'wagmi/chains';

export const modeMainnet = defineChain({
  id: 34443,
  name: 'Mode',
  network: 'mode',
  nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
  rpcUrls: {
    default: {
      http: ['https://mainnet.mode.network'],
    },
    public: {
      http: ['https://mainnet.mode.network'],
    },
  },
  blockExplorers: {
    default: {
      name: 'Blockscout',
      url: 'https://explorer.mode.network/',
    },
  },
  // contracts: {
  //   multicall3: {
  //     // address: '0xBAba8373113Fb7a68f195deF18732e01aF8eDfCF',
  //     blockCreated: 3019007,
  //   },
  // },
  testnet: false,
});

export const jbcMainnet = defineChain({
  id: 8899,
  name: 'JIBCHAIN L1',
  network: 'jbc',
  nativeCurrency: { name: 'Ether', symbol: 'JBC', decimals: 18 },
  rpcUrls: {
    default: {
      http: ['https://rpc-l1.jibchain.net'],
    },
    public: {
      http: ['https://rpc-l1.jibchain.net'],
    },
  },
  blockExplorers: {
    default: {
      name: 'Blockscout',
      url: 'https://exp.jbcha.in',
    },
  },
  contracts: {
    ensRegistry: {
      address: '0x87e31EbcAF0129C281b357E956660E057D36a39F',
    },
    ensUniversalResolver: {
      address: '0x80996350689A851016f83F112038Fb8Bac867d08',
      blockCreated: 2479788,
    },
    multicall3: {
      address: '0xc0C8C486D1466C57Efe13C2bf000d4c56F47CBdC',
      blockCreated: 2299048,
    },
  },
  testnet: false,
});

const getSupportedChains = (): Chain[] => {
  if (localConfig.isOnTestnet) {
    return [bscTestnet, sepolia, modeTestnet, jbcMainnet];
  }

  if (localConfig.environment === 'preview') {
    return [jbcMainnet];
  }

  return [jbcMainnet];
};

// export const governanceChain = localConfig.isOnTestnet ? bscTestnet : bsc;
export const governanceChain = jbcMainnet;

const supportedChains = getSupportedChains();
export const chains = supportedChains.filter(c => localConfig.rpcUrls[c.id as ChainId]?.http);
// export const defaultChain = chains[0];
export const defaultChain = chains[0];
