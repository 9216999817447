import { SVGProps } from 'react';

const SvgPerson = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    {...props}
  >
    <circle cx="16" cy="9" r="6" fill="currentColor" className="fill-current" />
    <path
      d="M6.27705 22.2455C7.21982 19.5338 9.91458 18 12.7854 18H19.2146C22.0854 18 24.7802 19.5338 25.7229 22.2455C26.3054 23.9207 26.8319 25.9371 26.9667 28.0007C27.0027 28.5518 26.5523 29 26 29H6C5.44772 29 4.99726 28.5518 5.03327 28.0007C5.16811 25.9371 5.6946 23.9207 6.27705 22.2455Z"
      fill="currentColor"
      className="fill-current"
    />
  </svg>
);

export default SvgPerson;
