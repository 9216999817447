import { SVGProps } from 'react';

const SvgJiblend = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clip-path="url(#clip0_439_4611)">
      <path
        d="M12.2663 23.2117C13.7167 22.6385 15.141 22.0687 16.5034 21.507C16.9036 20.4184 18.2272 19.1725 20.0361 18.3152C21.8625 17.4499 23.6842 17.2173 24.7783 17.6118C26.7182 16.459 27.901 15.3814 27.9097 14.416C27.8552 14.0475 27.7521 13.5616 27.6786 13.1966C27.5216 12.5522 27.5129 12.5297 27.064 11.3381C26.8983 10.9372 26.6209 10.4276 26.4199 10.043C27.4677 12.2821 18.8446 16.0107 9.92212 19.6381C5.63926 21.3792 5.61725 21.4255 5.47707 21.7257C5.43885 21.8067 5.93467 22.4782 5.99144 22.5656C6.19243 22.8762 6.4079 23.177 6.63728 23.4674C7.04448 23.9828 7.56985 24.7087 8.1485 25.0321C8.32923 24.8169 8.60668 24.7075 8.85923 24.5942C9.97599 24.0909 11.1287 23.6623 12.2663 23.2111V23.2117Z"
        fill="currentColor"
      />
      <path
        d="M5.36581 12.5505C5.70177 12.0484 6.29374 11.5527 7.04443 11.1969C8.0187 10.7353 9.00688 10.6295 9.64751 10.8597C11.8364 9.99492 14.2269 9.01736 16.2965 8.02476C20.0094 6.24375 21.0642 5.11174 19.5206 4.56338L18.7722 4.36093C15.4989 3.40361 12.2778 4.64899 12.2778 4.64899C8.89856 5.70175 6.23061 8.2758 4.903 11.5376C4.69679 12.0443 4.49754 12.5759 4.38806 13.1133L4.64582 12.8894C4.80395 12.7986 5.03912 12.6887 5.36523 12.5505H5.36581Z"
        fill="currentColor"
      />
      <path
        d="M25.792 19.0753C25.6657 20.2594 24.2263 21.7627 22.1434 22.7495C20.0854 23.7248 18.0331 23.8966 17.0212 23.2724C15.9363 23.7138 14.8132 24.1592 13.6727 24.6069C11.2132 25.5723 10.1955 25.9726 9.88678 26.215C9.88678 26.2427 9.88678 26.2803 9.88678 26.3081C12.4893 27.8462 15.6948 28.3991 18.6489 27.7044C18.6854 27.6963 18.7242 27.6871 18.756 27.6802C18.7578 27.6802 18.7769 27.6744 18.811 27.6651C19.3955 27.5182 19.9938 27.3169 20.5991 27.0485C21.1326 26.8322 21.7205 26.5626 22.2725 26.2433C22.572 26.053 22.9613 25.784 23.2503 25.5793C23.8064 25.1541 23.855 25.1073 24.6648 24.3246C24.7719 24.213 24.8855 24.0863 24.9996 23.9527C26.4975 22.314 27.0321 20.7765 27.2042 20.3149C27.3038 20.0552 27.3994 19.7926 27.4793 19.5681C27.6036 19.1682 27.7419 18.5614 27.894 17.7478C27.945 17.436 27.9699 17.0149 28 16.7008C27.8598 17.4447 27.0599 18.2406 25.792 19.0753Z"
        fill="currentColor"
      />
      <path
        d="M24.2628 19.0284C23.9088 18.2833 22.2013 18.3533 20.4485 19.1834C18.6957 20.014 17.5616 21.2912 17.9155 22.0357C18.2694 22.7807 19.977 22.7107 21.7298 21.8806C23.4825 21.05 24.6167 19.7728 24.2628 19.0284Z"
        fill="currentColor"
      />
      <path
        d="M25.1212 8.2128L25.061 8.14338C24.7852 7.82871 24.3421 7.39565 23.7316 6.84421C23.2485 6.44104 23.2294 6.42831 22.2221 5.76831L21.6429 5.44092C24.1382 6.82107 16.1639 10.213 10.0362 12.6529C9.69849 13.1486 9.11231 13.6368 8.37031 13.9885C7.40936 14.4437 6.43567 14.5525 5.79445 14.335C5.6537 14.287 5.10806 14.6184 4.95514 14.6861C4.78717 14.7601 4.61513 14.833 4.45468 14.9244C4.32378 14.9996 4.11815 15.1089 4.05443 15.2564C3.94554 15.5081 4.03184 16.0038 4.03822 16.2751C4.04748 16.6597 4.07471 17.0438 4.11989 17.4262C4.19924 18.0977 4.26643 19.0383 4.60587 19.63C4.79817 19.3529 5.16598 19.2072 5.46255 19.0655C6.5156 18.5634 7.60804 18.1376 8.68715 17.6969C9.12158 17.5193 9.55658 17.3423 9.99159 17.1659C18.7299 13.616 26.4627 10.2599 25.1212 8.21395V8.2128Z"
        fill="currentColor"
      />
      <path
        d="M7.39722 11.9408C6.63148 12.3035 6.14897 12.8901 6.32043 13.2498C6.4913 13.6102 7.25126 13.6079 8.017 13.2446C8.78275 12.882 9.26525 12.2954 9.0938 11.9356C8.92293 11.5753 8.16297 11.5776 7.39722 11.9408Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_439_4611">
        <rect width="24" height="24" fill="white" transform="translate(4 4)" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgJiblend;
